<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">单位根检验结果</h1>
            <div class="result-area" v-if="modelInfo" id="modelInfo">
                <divider-title name="模型说明" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content"><!---->
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" :style="{width:key===0?'100px':'auto'}" v-for="(item,key) in modelInfo[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title">{{item.val}}</span></div></span></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in modelInfo" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="coef" id="coef">
                <divider-title name="模型系数" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" style="width:100px" v-for="(item,key) in coef[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title">{{item.val}}</span></div></span></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in coef" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="testResult" id="testResult">
                <divider-title name="检验结果" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead" v-html="testResult.header">
                                            </thead>
                                            <tbody class="ant-table-tbody" v-html="testResult.body">
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="result-area" v-if="modeleEvaluatioin" id="modeleEvaluatioin">
                <divider-title name="模型评价" />
                <div class="ant-table-wrapper">
                    <div class="ant-spin-nested-loading">
                        <div class="ant-spin-container">
                            <div class="ant-table ant-table-scroll-position-left ant-table-middle">
                                <div class="ant-table-content">
                                    <div class="ant-table-body">
                                        <table class="">
                                            <thead class="ant-table-thead">
                                                <tr>
                                                    <th :key="key" style="width:100px" v-for="(item,key) in modeleEvaluatioin[0]"><span class="ant-table-header-column"><div><span class="ant-table-column-title">{{item.val}}</span></div></span></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody class="ant-table-tbody">
                                                <tr class="ant-table-row ant-table-row-level-0" data-row-key="0" v-for="(item,key) in modeleEvaluatioin" :key="key" v-show="key>0">
                                                    <td v-for="(i,k) in item" :key="k">{{i.val}}</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a-layout-content>
    </a-layout>
</template>

<script>
import { getSessionItem } from '../../until/tools'
import DividerTitle from './components/DividerTitle.vue';
export default {
    components:{DividerTitle},
    computed:{
        result() {
            let data = getSessionItem('analysis_unitCheck');
            return data.analysis_unitCheck;
        },
        parts() {
            let arr = [
                {
                    name:'模型说明', 
                    keys:['modelInfo'],
                },
                {
                    name:'模型系数',
                    keys:['coef'],
                },
                {
                    name:'检验结果',
                    keys:['testResult'],
                },
                {
                    name:'模型评价',
                    keys:['modeleEvaluatioin'],
                },
            ]
            return arr;
        },
        modelInfo() {
            if(this.result) {
                return this.result.modelInfo.data;
            } else {
                return null;
            }
        },
        coef() {
            if(this.result) {
                return this.result.coef.data;
            } else {
                return null;
            }
        },
        testResult() {
            if(this.result) {
                return this.parseTable(this.result.testResult.data);
            } else {
                return null;
            }
        },
        modeleEvaluatioin() {
            if(this.result) {
                return this.result.modeleEvaluatioin.data;
            } else {
                return null;
            }
        }
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        parseTable(source) {
            var repeatCells = [];
            var tableHead = '<thead>'
            var tableBody = '<tbody>';

            source.forEach((value,key)=> {
                if(key<2) {
                    tableHead+='<tr>';
                    value.forEach((v)=> {
                        if(!v.colspan) {
                            v.colspan=0;
                        }
                        if(!v.rowspan) {
                            v.rowspan=0;
                        }
                        if(v.colspan>0||v.rowspan>0) {
                            repeatCells.push(v.val);
                        }
                        var th = '';
                        if(v.colspan>0||v.rowspan>0) {
                            th='<th colspan="'+(v.colspan+1)+'" rowspan="'+(v.rowspan+1)+'">'+v.val+'</th>';
                        }else if(!v.colspan&&!v.rowspan&&repeatCells.indexOf(v.val)!=-1) {
                            th='<th style="display:none;">'+v.val+'</th>';
                        } else {
                            th='<th style="width:150px">'+v.val+'</th>';
                        }
                        tableHead+=th;
                    });
                    tableHead+='<th style="width:60%"></th>';
                    tableHead+='</tr>';
                } else {
                    tableBody+='<tr>';
                    value.forEach((v)=> {
                        if(v.colspan>0||v.rowspan>0) {
                            repeatCells.push(v.val);
                        }
                        var td = '';
                        if(v.colspan>0||v.rowspan>0) {
                            td='<td colspan="'+(v.colspan+1)+'" rowspan="'+(v.rowspan+1)+'">'+v.val+'</td>';
                        }else if(!v.colspan&&!v.rowspan&&repeatCells.indexOf(v.val)!=-1) {
                            td='<td style="display:none;">'+v.val+'</td>';
                        } else {
                            td='<td>'+v.val+'</td>';
                        }
                        tableBody+=td;
                    });
                    tableBody+='<td></td>';
                    tableBody+='</tr>';
                }                
            });
            return {
                header:tableHead,
                body:tableBody
            }
        }
    }
}
</script>